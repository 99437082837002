import Footer from "../../Footer/Footer"
import Header from "../../Header/Header"
import NavBar from "../../Header/NavBar/NavBar"
import React, {useState, useEffect} from 'react' 
import axios from "axios"
import "./style.css"
import proxy_address from "../../../proxy"



export default function AboutPage(){
    
    const [data, setData] = useState([])
    useEffect(() => {
        axios
            .get(proxy_address + 'about/get')
            .then(response => {
                setData(response.data)
            })
    })



    return (
        <>
            <div className="nav_bar_background">
                <NavBar />
            </div>
            <div className="about">
                <div className="about-2">
                    <div className="about-title">
                        <span>
                            О нас
                        </span>
                    </div>
                    <div className="about-text">
                        <p>
                           {
                            data.text
                           }
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}