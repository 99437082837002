import Marquee from "react-fast-marquee";
import FeedBackCarouselItem from "../Pages/Сallout/CalloutItem"
import "./style.css"

import ico1 from "../../assets/l1.jpg"
import ico2 from "../../assets/l2.jpg"
import ico3 from "../../assets/l3.jpg"
import ico4 from "../../assets/l4.jpg"

export default function FeedBackCarousel() {
    return (
        <div className="feedback-carousel-2 centered">
            <div>
                <div className="home-page--feedback-carousel-title">
                    <span>Отзывы о нас</span>
                </div>
            </div>
            <Marquee
                pauseOnHover={true}
            >
                <FeedBackCarouselItem icon={ico1} name={"Максим Семенов"} company={"ООО \"Комфорт\""}>Обратился в юрфирму для регистрации ООО, проблема была в том что у нас четыре учредителя и все проживают в разных городах. В процессе регистрации не возникло никаких проблем. Нас грамотно проконсультировали и организовали процесс под ключ. Спасибо.</FeedBackCarouselItem>
                <FeedBackCarouselItem icon={ico2} name={"Олег Игнатьев"} company={"ООО \"МедСтандарт\""}>Рекомендую компанию как надежного и профессионального партнера. Цены в пределах нормы, отношение к заказчику на высоте. Всегда по срокам укладываются.</FeedBackCarouselItem>
                <FeedBackCarouselItem icon={ico3} name={"Александр Борщев"} company={"ООО \"Белая Полоса\""}>Хочу выразить огромную признательность всем сотрудникам вашей компании. Ваша работа всегда грамотна и эффективна и действительно помогла моей компании выйти на новый уровень развития. Все специалисты компетентны, консультируют по непонятным моментам. Именно помощь вот таких профи так была нужна нашей компании в момент кризисной обстановки в стране! Спасибо!</FeedBackCarouselItem>
                <FeedBackCarouselItem icon={ico4} name={"Александр Борщев"} company={"ООО \"Комфорт\""}>Хочу выразить огромную признательность всем сотрудникам вашей компании. Ваша работа всегда грамотна и эффективна и действительно помогла моей компании выйти на новый уровень развития. Все специалисты компетентны, консультируют по непонятным моментам. Именно помощь вот таких профи так была нужна нашей компании в момент кризисной обстановки в стране! Спасибо!</FeedBackCarouselItem>
            </Marquee>
        </div>
    )
}