import Marquee from "react-fast-marquee";
import "./style.css"
import PartnerElement from "./PartnerElement";
import logo1 from "../../assets/logo1.jpg"
import logo2 from "../../assets/logo2.jpg"
import logo3 from "../../assets/logo3.jpg"

export default function MarqueePartners() {
    return (
        <div className="marquee-partners centered">
            <div className="marquee-partners-title">
                <span >Наши партнеры</span>
            </div>
            <Marquee
                pauseOnHover={true}
            >
                <PartnerElement icon={logo1} description={"Примерное описание"}/>
                <PartnerElement icon={logo2} description={"Примерное описание"}/>
                <PartnerElement icon={logo3} description={"Примерное описание"}/>
                <PartnerElement icon={logo1} description={"Примерное описание"}/>
                <PartnerElement icon={logo2} description={"Примерное описание"}/>
                <PartnerElement icon={logo3} description={"Примерное описание"}/>
                <PartnerElement icon={logo1} description={"Примерное описание"}/>
                <PartnerElement icon={logo2} description={"Примерное описание"}/>
                <PartnerElement icon={logo3} description={"Примерное описание"}/>
            </Marquee>
        </div>
    )
}