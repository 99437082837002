
import axios from "axios"
import { useEffect, useState } from "react"
import NavBar from "../../Header/NavBar/NavBar"
import proxy_address from "../../../proxy"

import "./style.css"

export default function PricesPage(){
    const request = proxy_address + 'gallery/get/all'
    const [data, setData] = useState([])


    useEffect(() => {
        axios
            .get(request)
            .then(response => {
                setData(response.data)
            })
    }, [])
    return (
        <>
            <div className="nav_bar_background">
                <NavBar />
            </div>
            <div className="photos-grid">
            {!data || Object.keys(data).length === 0 ? (
                <p>Loading...</p>
            ) : (
                data.map((item, index) => (
                    <div key={index} className="photo-item">
                        <img 
                            src={item.image} 
                            alt={item.title}
                        />
                    </div>
                ))
            )}
            </div>

        </>
    )
}