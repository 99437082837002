import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Input } from 'antd';
const { TextArea } = Input;

axios.defaults.withCredentials = true;

export default function AboutEdit() {
    const get_request = 'http://213.171.15.56:8001/about/get'
    const put_request = 'http://213.171.15.56:8001/admin/about_edit?new_text='

    const [data, setData] = useState([])

    const auth_request = "http://213.171.15.56:8001/admin/auth/login"
    useEffect(() => {
        axios
            .post(auth_request, { username: '', password: '' })
        }, []
    )

    useEffect(() => {
        axios
            .get(get_request)
            .then(response => {
                setValue(response.data.text)
            })
    }, [])

    function update_data() {
        axios.put(put_request + value, { withCredentials: true })
    }

    const [value, setValue] = useState('');
    return (
        <>
            <div
                style={{
                margin: '24px 0',
                }}
            />
            <TextArea
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder="Controlled autosize"
                autoSize={{
                minRows: 3,
                maxRows: 5,
                }}
            />
            <Button 
                onClick={update_data} 
                type={"primary"}
                >Изменить</Button>
        </>
    );
}