import NavBar from "../../Header/NavBar/NavBar";
import Item from "./Item";
import "./style.css"
import axios from "axios"
import { useEffect, useState } from "react";
import proxy_address from "../../../proxy"

export default function ServiceCategoriesPage(){
    const [data, setData] = useState([])
    useEffect(() => {
        axios
            .get(proxy_address + 'service/get')
            .then(response => {
                setData(response.data)
            })
    }, [])
    const path = "/services/"

    return (
        <>
            <div className="nav_bar_background">
                <NavBar />
            </div>
            <div className="service-categories-page">
                <div className="service-categories-page-content">
                    <h1>Услуги</h1>
                    <div className="service-categories-page-content-items centered">
                        {
                            !data || Object.keys(data).length === 0 ? "Loading..." : data.map((item, index) => {
                                return (
                                    <Item image={item.image} title={item.title} link={path + item.title}/>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

        </>
    )
}