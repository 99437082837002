import "./style.css"
import logo from "../../assets/logo.png"
import { useState } from "react"
import { Modal, Form, Checkbox, Input, Alert } from "antd"
import axios from "axios"


export default function Header() {
    const [isContactsModalOpen, setIsContactsModalOpen] = useState(false)
    
    const [address, setAddress] = useState('')
    const [pnumber, setPnumber] = useState('')
    const [email, setEmail] = useState('')
    const [question, setQuestion] = useState('')

    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)
    const [feedbackButtonActive, setFeedbackButtonActive] = useState(true)

    const [resp, setResp]= useState([])
    function send_feedback(){
        const request = `http://213.171.15.56:8001/applications/add_application?address=+${address}&gmail=${email}&phone=${pnumber}&question=${question}`
        axios
            .post(request)
            .then(response => {
                setResp(response.data)
            })
        console.log(resp)
        setIsFeedbackModalOpen(false)
 
    }

    return (
        <header>
            <div className="header-2 centered">
                <div className="title">
                    <div className="title-logo">
                        <img src={logo} alt="none" className="logo" />
                    </div>
                    <div className="title-texts">
                        <div className="title-text-h1">
                            <span>ООО"ДВ-Прогресс"</span>
                        </div>
                        <div className="title-text-h2">
                            <span>инженерные системы</span>
                        </div>
                    </div>
                </div>
                <div className="contacts">
                    <div className="contacts-number">
                        <span>+7 (900) 338-33-68</span>
                    </div>
                    <div className="contacts-address">
                        <span>г. Хабаровск, ул.Владивостокская, д.22</span>
                    </div>
                </div>

                <div className="buttons">
                    <button className="all-contacts-btn" onClick={() => setIsContactsModalOpen(true)}>Все контакты</button>
                    <button className="feedback-btn" onClick={() => setIsFeedbackModalOpen(true)}>Написать нам</button>
                </div>

                <Modal 
                    // title="" 
                    open={isContactsModalOpen} 
                    centered
                    onCancel={() => setIsContactsModalOpen(false)}
                    footer={[]}
                >
                    <p>
                        +7 (900) 338-33-68 <br />
                        г. Хабаровск, ул.Владивостокская, д.22
                        <br />
                        <br />
                        dv-progress@mail.ru
                        <br />
                        <br />
                        <a href="/contacts" className="more_btn">больше</a>
                    </p>
                </Modal>

                <Modal 
                    // title="" 
                    open={isFeedbackModalOpen} 
                    centered
                    onCancel={() => setIsFeedbackModalOpen(false)}
                    footer={[
                        <button 
                            className="feedback-btn" 
                            onClick={() => send_feedback()}
                            disabled={feedbackButtonActive}
                        >
                            Отправить
                        </button>
                    ]}
                >
                <Form className="form-feedback">
                    <span className="form-feedback-title">Написать нам</span>
                    <Input placeholder="Ваш адрес" onChange={(e) => setAddress(e.currentTarget.value)}/>
                    <Input placeholder="Телефон *" onChange={(e) => setPnumber(e.currentTarget.value)}/>
                    <Input placeholder="E-mail" onChange={(e) => setEmail(e.currentTarget.value)}/>
                    <Input placeholder="Ваш вопрос" onChange={(e) => setQuestion(e.currentTarget.value)}/>
                    <span className="form-feedback access">
                        <Checkbox className="form-feedback checkbox" onChange={(e) => setFeedbackButtonActive(!e.target.checked)}>
                                Ознакомлен с <br />
                                пользовательским <br />
                                соглашением</Checkbox>  
                    </span>
                </Form>
                </Modal>
            </div>
        </header>
    )
}
