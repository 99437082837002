import NavBar from "../../Header/NavBar/NavBar"
import { useEffect, useState } from "react"
import axios from "axios"
import proxy_address from "../../../proxy"

import "./style.css"

export default function FaqPage(){
    const request = proxy_address + 'faq/get/'
    const [data, setData] = useState([])

    useEffect(() => {
        axios
            .get(request)
            .then(response => {
                setData(response.data)
            })
    }, [])

    return (
        <>
            <div className="nav_bar_background">
                <NavBar />
            </div>
            <div className="faq-container">
                {
                    !data || Object.keys(data).length === 0 ? <p>Loading...</p> : data.map((item, index) => (
                            <div key={index} className="faq-item">
                                <div className="faq-question">
                                    <span>{item.question}</span>
                                </div>
                                <div className="faq-answer">
                                    <span>{item.answer}</span>
                                </div>
                            </div>
                    ))
                }
            </div>
        </>
    )
}