import axios from "axios"
import { useEffect, useState } from "react"
import NavBar from "../../Header/NavBar/NavBar"
import proxy_address from "../../../proxy"

import "./style.css"

export default function OurProducts(){
    const request = proxy_address + 'ourproducts/get?limit=ALL&offset=0'
    const [data, setData] = useState([])

    useEffect(() => {
        axios
            .get(request)
            .then(response => {
                setData(response.data)
            })
    }, [])
    
    return (
        <>
            <div className="nav_bar_background">
                <NavBar />
            </div>
            <div className="products-container">
                {
                    !data || Object.keys(data).length === 0 ? <p className="loading">Loading...</p> : data.map((item, index) => (
                            <div key={index} className="product-item">
                                <span className="product-title">{item.title}</span>
                            </div>
                    ))
                }
            </div>
        </>
    )
}