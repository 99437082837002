import NavMenuDrawner from "../../NavMenuDrawer/NavMenuDrawer"
import "./style.css"

export default function NavBar(){
    return (
            <div className="nav_bar">
                <ul className="nav_list">
                    <li>
                        <a href="/">Главная</a>
                    </li>
                    <li>
                        <a href="services">Услуги</a>
                    </li>
                    <li>
                        <a href="goods">Товары</a>
                    </li>
                    <li>
                        <a href="ourproducts">Наша продукция</a>
                    </li>
                    <li>
                        <a href="photos">Галерея</a>
                    </li>
                    <li>
                        <a href="about">О нас</a>
                    </li>
                    <li>
                        <a href="faq">Вопросы и ответы</a>
                    </li>
                    <li>
                        <a href="callout">Отзывы</a>
                    </li>
                    <li>
                        <a href="price_list">Прайс лист</a>
                    </li>
                    


                    
                </ul>
                <div className="nav_button">
                    <NavMenuDrawner />
                </div>
            </div>
    )
}
