import NavBar from "../../Header/NavBar/NavBar"

import "./style.css"

export default function ContactsPage(){

    const address = "680031 г.хабаровск, пер Дежнева, д. 17-32"

    return (
        <>
            <div className="nav_bar_background">
                <NavBar />
            </div>
            <div className="contacts-container">
                <div className="contact-info">
                    <h2>Контактная информация</h2>
                    <p><strong>Адрес:</strong> {address}</p>
                    <p><strong>Телефон:</strong> +7 (900)-338-33-68</p>
                    <p><strong>Email:</strong> dv-progress@mail.ru</p>
                    <p><strong>ИНН:</strong> 2722101820 </p>
                    <p><strong>ОГРН:</strong> 1112722002304 </p>
                    <p><strong>КПП:</strong> 272401001 </p>
                </div>
                <div className="map-container">
                    <iframe 
                        src={`https://yandex.ru/map-widget/v1/?um=constructor%3A0f8b6a5e9f2a5c7e3d1b9f4a2c6e8d0b&source=constructor&ll=37.609218,55.757547&z=16&text=${encodeURIComponent(address)}`}
                        width="600" 
                        height="450" 
                        style={{border:0}} 
                        allowFullScreen="" 
                        loading="lazy" 
                        referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </div>
        </>
    )
}
