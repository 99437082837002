import avatar from "../../../assets/avatar-unknown-user.jpg"
import "./style.css"

export default function CalloutItem({ icon, name, children}) {
    return (
    <div className="feedback-box">
        <div className="feedback-box-user">
            <div className="feedback-box-user-icon">
                <img src={!icon ? avatar : icon} alt={avatar} />
            </div>
            <div className="feedback-box-user-name">
                <span>{name}</span>
            </div>
        </div>
        <div className="feedback-box-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" data-prefix="isx8hcpzc"><path data-name="15iconka.svg" d="M41.272 29.749a22.353 22.353 0 0 1-11.528 11.524A20.615 20.615 0 0 1 21.5 43H.429a.429.429 0 0 1-.282-.751l6.09-5.328-.309-.323A22.3 22.3 0 0 1 0 21.5 21.933 21.933 0 0 1 17.582.381a.428.428 0 1 1 .164.84 20.84 20.84 0 0 0-10.58 35.4.428.428 0 0 1-.012.633l-5.585 4.887H21.5a20.632 20.632 0 0 0 3.754-40.922.428.428 0 1 1 .164-.84A21.933 21.933 0 0 1 43 21.5a20.619 20.619 0 0 1-1.728 8.249zm-30.05-16.386h20.557a.428.428 0 1 1 0 .856H11.222a.428.428 0 1 1 0-.856zm0 15.418h20.557a.428.428 0 1 1 0 .856H11.222a.428.428 0 1 1 0-.856zm0-7.709h20.557a.428.428 0 1 1 0 .856H11.222a.428.428 0 1 1 0-.856zM21.5.857a.428.428 0 1 1 .428-.428.429.429 0 0 1-.428.428z" fill-rule="evenodd" class="path-iza47m0md"></path></svg>
        </div>
        <div className="feedback-message">
            <p className="feedback-message-text">{children}</p>
        </div>
    </div>
    )
}