import Footer from "../../Footer/Footer"
import React, {useState, useEffect} from 'react' 
import { useParams } from "react-router"
import axios from "axios"
import NavBar from "../../Header/NavBar/NavBar"
import proxy_address from "../../../proxy"

import "./style.css"

export default function Services(){
    const {category_title, service_title} = useParams()
    const [data, setData] = useState([])
    const request = proxy_address + 'service/get/' + category_title + '/' + service_title;
    console.log(request)
    
    useEffect(() => {
        axios
            .get(request)
            .then(response => {
                // setData(response.data)
                setData(response.data)
            })
    }, [])

    console.log(data)

    return (
        <>
            <div className="nav_bar_background">
                <NavBar />
            </div>
            <div style={{ minHeight: '700px' }}>
                {
                    !data || Object.keys(data).length === 0 ? <p>Loading...</p> : (
                        <div>
                            <h1>{data.title}</h1>
                            <p>{data.description}</p>
                        </div>
                    )
                }
            </div>
            <Footer /> 
        </>
    )
}