import { Form, Input, Upload, Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import proxy_address from '../../../proxy'

const NewCallout = () => {
  const [form] = Form.useForm()

  const onFinish = (values) => {
    fetch(`${proxy_address}comments/add?name=${values.name}&email=${values.email}&description=${values.description}&image=${values.image}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            if (response.status === 200) {
                form.resetFields()
                console.log('Form cleared successfully')
            }
    })
        // .then(data => console.log(data))
        .catch(error => console.error('Error:', error))

  }


  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Form form={form} onFinish={onFinish} layout="vertical" style={{ width: '500px', height: '600px', overflow: 'auto' }}>
        <Form.Item
          name="name"
          label="Ваше имя"
          rules={[{ required: true, message: 'Пожалуйста, введите ваше имя' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="Почта"
          rules={[
            { required: true, message: 'Пожалуйста, введите вашу почту' },
            { type: 'email', message: 'В указанной почте допущена ошибка' }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Ваш комментарий"
          rules={[{ required: true, message: 'Комментарий не должен быть пустым' }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          name="image"
          label="Аватар/логотип"
          valuePropName="fileList"
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e
            }
            return e && e.fileList
          }}
        >
          <Upload name="image" listType="picture">
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>  )
}

export default NewCallout
