import Footer from "../../Footer/Footer"
import Item from "../ServiceCategoriesPage/Item"
import React, {useState, useEffect} from 'react' 
import { useParams } from "react-router"
import axios from "axios"
import NavBar from "../../Header/NavBar/NavBar"
import proxy_address from "../../../proxy"

import "./style.css"

export default function ServicesPage(){
    const {category_title} = useParams()
    const [data, setData] = useState([])
    const request = proxy_address + 'service/get/' + category_title;
    console.log(request)
    
    useEffect(() => {
        axios
            .get(request)
            .then(response => {
                setData(response.data)
            })
    }, [])

    
    return (
        <>
            <div className="nav_bar_background">
                <NavBar />
            </div>  
            <h1>{category_title}</h1>
            <div>
                    <div className="service-categories-page-content-items centered">
                    {
                        !data || Object.keys(data).length === 0 ? "Loading..." : data.map((item, index) => (
                            <div key={index} className="category_object">
                                <Item image={item.image} title={item.title} link={"/services/" + category_title + "/" + item.title}/>
                            </div>
                        ))
                    }
                </div>  
            </div>
            <Footer /> 
        </>
    )
}