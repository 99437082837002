import image_placehololder from "../../../assets/no_image_available.png"
import "./style.css"

export default function Item({image, title, link}) {
    
    return (
        <div className="service-categories-page-content-item">
            <div className="service-categories-page-content-item-image">
                <img src={image ?  image : image_placehololder} alt="" />
            </div>
            <div className="service-categories-page-content-item-title">
                <span>
                    <a href={link}>
                        {title}
                    </a>
                </span>
            </div>
        </div>
    )
}
