import axios from "axios"
import { useEffect, useState } from "react"
import Item from "../Item"
import NavBar from "../../../Header/NavBar/NavBar"
import proxy_address from "../../../../proxy"

export default function GoodsCategoryPage(){
    const [data, setData] = useState([])
    const request = proxy_address + 'goods/get/categories'
    console.log(data)

    useEffect(() => {
        axios
            .get(request)
            .then(response => {
                setData(response.data)
            })
    }, [])
    
    const path = "/goods/"

    return (
        <>
            <div className="nav_bar_background">
                <NavBar />
            </div>
            <h1>GoodsCategoryPage</h1>
            <div>
            {
                    !data || Object.keys(data).length === 0 ? <p>Loading...</p> : data.map((item, index) => (
                        <Item image={item.image} title={item.title} link={path + item.title}/>
                    ))
                }
            </div>
        </>
    )
}