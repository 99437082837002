import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import AboutEdit from './AboutEdit';
import axios from 'axios';
import IsAuth from './IsAuth';
import { Navigate, redirect, replace } from 'react-router';


const items = [
  {
    key: '1',
    label: 'AboutEdit',
    children: <AboutEdit />,
  },
  {
    key: '2',
    label: 'Tab 2',
    children: 'Content of Tab Pane 2',
  },
  {
    key: '3',
    label: 'Tab 3',
    children: 'Content of Tab Pane 3',
  },
];

axios.defaults.withCredentials = true;

export default function AdminMainPage() {
    const [authStatus, setAuthStatus] = useState('')
    const get_request = "http://213.171.15.56:8001/admin/auth/login"
    useEffect(() => {
      axios
          .post(get_request, { username: '', password: '' })
          .then(response => {
            setAuthStatus(response.data)
          })
    }, [])

    console.log(authStatus)
    
    return (
        <>
            <IsAuth />
            <Tabs defaultActiveKey="2" items={items} />
        </>
    )
}
