import proxy_address from "../../../proxy"
import NavBar from "../../Header/NavBar/NavBar"
import CalloutItem from "./CalloutItem"
import axios from "axios"
import { useEffect, useState } from "react"

export default function Callout(){
    const [data, setData] = useState([])
    useEffect(() => {
        axios//http://213.171.15.56:8001
            .get(proxy_address + 'comments/get/all?limit=ALL&offset=0')
            .then(response => {
                setData(response.data)
            })
    }, [])

    console.log(data)

    return (
        <>
            <div className="nav_bar_background">
                <NavBar />
            </div>
            <div className="button-container">
                <button onClick={() => window.location.href = '/callout/new'} className="redirect-button" style={{
                    padding: '10px 20px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: '#ffffff',
                    backgroundColor: '#007bff',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease'
                }}>
                    Оставить комментарий
                </button>
            </div>
            <div className="callout-items-grid">
                {
                    !data || Object.keys(data).length === 0 ? "Loading..." : data.map((item, index) => {
                        return (
                            <CalloutItem icon={""} name={item.name}>{item.description}</CalloutItem>
                        )
                    })
                }
            </div>

            
        </>
    )
}