import { useState } from "react"
import { Modal, Form, Checkbox, Input } from "antd"

export default function FeedBack() {
    const [{address,
            pnumber,
            email,
            question}, setData] = useState('')
    
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)
    const [feedbackButtonActive, setFeedbackButtonActive] = useState(true)

    return (
        <>
            <button className="feedback-btn" onClick={() => setIsFeedbackModalOpen(true)}>Написать нам</button>
            <Modal 
                // title="" 
                open={isFeedbackModalOpen} 
                centered
                onCancel={() => setIsFeedbackModalOpen(false)}
                footer={[
                    <button 
                        className="feedback-btn" 
                        onClick={() => setIsFeedbackModalOpen(false)}
                        disabled={feedbackButtonActive}
                    >
                        Отправить
                    </button>
                ]}
            >
            <Form className="form-feedback">
                <span className="form-feedback-title">Написать нам</span>
                <Input placeholder="Ваш адрес" onChange={(e) => setData({"address": e.currentTarget.value})}/>
                <Input placeholder="Телефон *" onChange={(e) => setData({"pnumber": e.currentTarget.value})}/>
                <Input placeholder="E-mail" onChange={(e) => setData({"email": e.currentTarget.value})}/>
                <Input placeholder="Ваш вопрос" onChange={(e) => setData({"question": e.currentTarget.value})}/>
                <span className="form-feedback access">
                    <Checkbox className="form-feedback checkbox" onChange={(e) => setFeedbackButtonActive(!e.target.checked)}>
                            Ознакомлен с <br />
                            пользовательским <br />
                            соглашением</Checkbox>  
                </span>
            </Form>
            </Modal>
        </>
    )
}