import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css'

import HomePage from './components/Pages/HomePage/HomePage';
import AboutPage from './components/Pages/AboutPage/AboutPage';
import ContactsPage from './components/Pages/СontactsPage/СontactsPage';
import FaqPage from './components/Pages/FaqPage/FaqPage';
import PhotosPage from './components/Pages/PhotosPage/PhotosPage';
// import ProductsPage from './components/Pages/ProductsPage/ProductsPage';
// import PricesPage from './components/Pages/PhotosPage/PhotosPage';

import ServiceCategoriesPage from './components/Pages/ServiceCategoriesPage/ServiceCategoriesPage'; 
import ServicesPage from './components/Pages/ServicesPage/ServicesPage';
import ServicePage from './components/Pages/ServicePage/ServicePage'; 

import GoodsCategoryPage from "./components/Pages/GoodsPages/GoodsCategoryPage/GoogsCategoryPage"; // Все категории товаров
import GoodsBrandsPage from './components/Pages/GoodsPages/GoodsBrandsPage/GoodsBrandsPage';
import GoodsBrandPage from "./components/Pages/GoodsPages/GoodsBrandPage/GoodsBrandPage"; // 
import GoodsPage from "./components/Pages/GoodsPages/GoodsPage/GoodsPage"

import OurProducts from './components/Pages/OurProducts/OurProducts';

import Header from './components/Header/Header';
import Callout from './components/Pages/Сallout/Сallout';
import AdminMainPage from './components/Pages/Admin/AdminMainPage';
import AuthPage from './components/Pages/Admin/AuthPage';
import NewCallout from './components/Pages/Сallout/NewCallout';
// import NavBar from './components/Header/NavBar/NavBar';

export default function App() {
  return (
    <>
    <Header />
    <Router>
          <Routes>
            <Route path='/' element={<HomePage/>} />

            <Route path='/services' element={<ServiceCategoriesPage/>} /> {/* Все категории услуг */}
            <Route path='/services/:category_title' element={<ServicesPage/>} /> {/* Все услуги в категории */}
            <Route path='/services/:category_title/:service_title' element={<ServicePage/>} /> {/* Информация об одной услуге */}
          
            <Route path='/goods' element={<GoodsCategoryPage />} /> {/* Все категории товаров*/}
            <Route path='/goods/:category_title' element={<GoodsBrandsPage />} /> {/* Все бренды в категории товаров*/}
            <Route path='/goods/:category_title/:brand_title' element={<GoodsBrandPage />} /> {/* Все товары в бренде категории */}
            <Route path='/goods/:category_title/:brand_title/:goods_title' element={<GoodsPage />} /> {/* Информация про 1 товар в категории и бренде */}

            <Route path='/ourproducts/' element={<OurProducts />} />

            <Route path='/photos' element={<PhotosPage/>} />

            <Route path='/about' element={<AboutPage/>} />

            <Route path='/faq' element={<FaqPage/>} />

            <Route path='/callout' element={<Callout/>}/>
            <Route path='/callout/new' element={<NewCallout />} />

            <Route path='/contacts' element={<ContactsPage/>} />

            <Route path='*' element={<HomePage/>} />

            {/* Admin
            <Route path='/admin' element={<AdminMainPage />} />
            <Route path="/admin/auth" element={<AuthPage />} /> */}
          </Routes>
      </Router>
    </>
  )
}
