import axios from "axios"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import NavBar from "../../../Header/NavBar/NavBar"
import Item from "../Item"
import proxy_address from "../../../../proxy"

export default function GoodsBrandsPage(){
    const { category_title } = useParams()
    const [data, setData] = useState([])
    
    const request = proxy_address + 'goods/get/brands/' + category_title

    useEffect(() => {
        axios
            .get(request)
            .then(response => {
                setData(response.data)
            })
    }, [])

    const path = "/goods/"

    return (
        <>
            <div className="nav_bar_background">
                <NavBar />
            </div>
            <h1>GoodsBrandsPage</h1>
            <div>
            {
                    !data || Object.keys(data).length === 0 ? <p>Loading...</p> : data.map((item, index) => (
                        <Item image={item.image} title={item.title} link={path + category_title + "/" + item.title}/>
                    ))
                }
            </div>
        </>
    )
}