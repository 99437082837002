import React, { useState, useEffect} from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import axios from 'axios';


const api = axios.create({
    baseURL: "http://213.171.15.56:8001",
    withCredentials: true,
    headers: {
        "Content-type": "application/json",
    },
})

export default function AuthPage() {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    function Auth() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: username, password: password })
        };
        const auth_request = "http://213.171.15.56:8001/admin/auth/login"
        console.log(username,password)
        fetch(auth_request, requestOptions).then(res => res.json())
        // useEffect(() => {
            // api
            //     .post(auth_request, { username: username, password: password }, {withCredentials: true}) 
            //     .then(response => {
            //         // Check if response.headers exists before accessing it
            //         if (response.headers) {
            //             const cookies = response.headers['set-cookie']
            //             console.log(response.headers)
            //             console.log(cookies)

            //             if (cookies) {
            //                 cookies.forEach(cookie => {
            //                     document.cookie = cookie
            //                 })
            //             }
            //         } else {
            //             console.log('Response headers are null or undefined')
            //         }
            //     })
        // }, [])
    }
    
    return (
        <>
            <div>
                <div className='auth-form' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Space direction="vertical">
                        <Input 
                            placeholder="Username" 
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <Input.Password
                            placeholder="Password"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button onClick={(e) => Auth()}>Войти</Button>
                    </Space>
                </div>
            </div>
        </>
        
    );
}