import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import {MenuUnfoldOutlined} from '@ant-design/icons';

import "./style.css"

export default function NavMenuDrawner() {
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };
    return (
      <>
        <Button className='nav_menu_btn' type="primary" placement={'left'} icon={<MenuUnfoldOutlined style={{color: '#000'}}/>} onClick={showDrawer}></Button>
        <Drawer title="Навигация" onClose={onClose} open={open} size={265}>
            <div className='nav_element'>
                <span>
                    <a href="/">Главная</a>
                </span>
            </div>
            <div className='nav_element'>
                <span>
                    <a href="about">О нас</a>
                </span>
            </div>
            <div className='nav_element'>
                <span>
                    <a href="services">Услуги</a>
                </span>
            </div>
            <div className='nav_element'>
                <span>
                    <a href="contacts">Контакты</a>
                </span>
            </div>
            <div className='nav_element'>
                <span>
                    <a href="faq">Вопросы и ответы</a>
                </span>
            </div>
            <div className='nav_element'>
                <span>
                    <a href="photos">Галерея</a>
                </span>
            </div>
            <div className='nav_element'>
                <span>
                    <a href="products">Продукция</a>
                </span>
            </div>
            <div className='nav_element'>
                <span>
                    <a href="prices">Цены</a>
                </span>
            </div>
        </Drawer>
      </>
    );
};