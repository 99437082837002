import axios from "axios"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import Item from "../Item"
import NavBar from "../../../Header/NavBar/NavBar"
import proxy_address from "../../../../proxy"

export default function GoodsPage(){
    const { category_title, brand_title, goods_title } = useParams()
    const [data, setData] = useState([])
    const request = proxy_address + 'goods/get/' + category_title + "/" + brand_title + "/" + goods_title

    console.log(data)

    useEffect(() => {
        axios
            .get(request)
            .then(response => {
                setData(response.data)
            })
    }, [])

    return (
        <>
            <div className="nav_bar_background">
                <NavBar />
            </div>
            <h1>GoodsBrand2Page</h1>
            <div>
            {
                !data || Object.keys(data).length === 0 ? <p>Loading...</p> : 
                        // <Item image={item.image} title={item.title} link={""}/>
                        <div>
                            <img src={data.image} />
                            <div>
                                <span>
                                    {data.title}
                                </span>
                            </div>
                            <div>
                                <p>
                                    {data.description}
                                </p>
                            </div>
                        </div>
            }
            </div>
        </>
    )
}