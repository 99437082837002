import "./style.css"

export default function PartnerElement({ icon, description }) {
    return (
        <div className="partner-element">
            <div className="partner-element-logo">
                <img src={icon} alt="error not found" />
            </div>
            <div className="partner-element-description">
                <span>{description}</span>
            </div>
        </div>
    )
}